import React, { useState, useEffect } from "react";
import Images from "../../constants/images";
import CustomerDetails from "./customerDetails";
import DoctorDetails from "./doctorDetails";
import Invoice from "./invoice";
import ProductDetails from "./productDetails";
import ScheduleCard from "./scheduleCard";
import SellerDetails from "./sellerDetails";
import "./styles.css";
import { getBill } from "./apis";
import { useParams } from "react-router-dom";
import Loader from "../../components/loader";
import NullScreen from "../../components/nullScreen";
import ScheduleSuccess from "./scheduleSuccess";
import ThankYouDrawer from "./thankYouDrawer";

function HomePage() {
  const [loader, setLoader] = useState(false);
  const [orderDetails, setOrderDetails] = useState();
  const [days, setDays] = useState("7");
  const { id } = useParams();
  const [drawerClassName, setDrawerClassName] = useState("");
  const [mainClassName, setMainClassName] = useState("");

  useEffect(() => {
    if (!id) {
      window.location.href = "https://www.digihealth.in/";
    }
    setLoader(true);
    getBill(`_id=${id}`, (data) => {
      if (data?.status === "success" && data?.result) {
        setOrderDetails(data?.result);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });

    if (!drawerClassName) {
      setTimeout(() => {
        setDrawerClassName("drawer-visible");
        setMainClassName("main-drawer-visible");
      }, 5000);
    }
  }, []);

  if (!orderDetails && !loader) {
    return (
      <NullScreen
        title="No Order Found"
        image={Images?.nullScreen}
        description="You have entered wrong order id"
      />
    );
  }

  return (
    <div
      className="main-container"
      style={{ overflow: drawerClassName ? "hidden" : "scroll" }}
    >
      <Loader loading={loader} />
      <div className="head-start d-flex flex-column p-common-2">
        <p className="text-family-bold text-color-secondary text-size-head pb">
          Order ({orderDetails?.invoice || orderDetails?.order_id || "NA"})
        </p>
        <CustomerDetails orderDetails={orderDetails} />
      </div>

      {!orderDetails?.is_scheduled ? (
        <ScheduleCard orderDetails={orderDetails} setDays={setDays} />
      ) : (
        <ScheduleSuccess
          title={`Scheduled successfully for every ${orderDetails?.scheduled_interval} days`}
        />
      )}

      <SellerDetails orderDetails={orderDetails} />

      {orderDetails?.doctor || orderDetails?.patient?.doctor?.doctorName ? (
        <DoctorDetails orderDetails={orderDetails} />
      ) : null}

      {orderDetails?.details
        ? orderDetails?.details?.map((details) => {
            return <ProductDetails productDetails={details} />;
          })
        : null}

      <Invoice orderDetails={orderDetails} />

      <div className="d-flex justify-content-between align-items-center terms-condition p-common-2 border-bottom-primary">
        <p className="text-color-micro">
          Download digihealth app on Play Store
        </p>
        <button
          className="schedule-card-button px-3"
          onClick={() => {
            window.open(
              `https://digihealth1.page.link/retailer?id=${
                orderDetails?.invoice || orderDetails?.id
              }`
            );
          }}
        >
          Download
        </button>
      </div>

      <ThankYouDrawer
        orderDetails={orderDetails}
        drawerClassName={drawerClassName}
        mainClassName={mainClassName}
        setDrawerClassName={setDrawerClassName}
        setMainClassName={setMainClassName}
      />
    </div>
  );
}

export default HomePage;
