import React from "react";
import Images from "../../../constants/images";
import "./styles.css";

const ThankYouDrawer = ({
  orderDetails,
  drawerClassName = "",
  mainClassName,
  setMainClassName,
  setDrawerClassName,
}) => {
  let whatsappText = `Hii ${
    orderDetails?.retailer?.store_name || "Retailer"
  }, Thanks for your wonderful service.`;
  let whatsappNum = orderDetails?.retailer?.contact_no?.includes("+91")
    ? orderDetails?.retailer?.contact_no
    : `+91${orderDetails?.retailer?.contact_no}`;

  return (
    <div className={`main-drawer ${mainClassName}`}>
      <div className={`thank-you-drawer-container ${drawerClassName}`}>
        <div
          className="cross"
          onClick={() => {
            setDrawerClassName("");
            setMainClassName("");
          }}
        >
          <i className="fas fa-times fa-2x"></i>
        </div>
        <div className="thank-you-text">
          Send a smile to{" "}
          <span className="extra-bold">
            {orderDetails?.retailer?.store_name || "Retailer"}
          </span>{" "}
          by letting them know you appreciate their efforts!
        </div>
        <img src={Images.drawerImage} className="drawer-image" alt="drawer" />
        <a
          href={`https://wa.me/${whatsappNum}?text=${whatsappText}`}
          className="whatsapp-button"
        >
          <i className="fab fa-whatsapp whatsapp-icon"></i>
          <div className="whatsapp-text">Say Thanks</div>
        </a>
      </div>
    </div>
  );
};

export default ThankYouDrawer;
